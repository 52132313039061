
import { computed } from 'vue';
import { useStore } from 'vuex';

import { sygnet } from '@/assets/brand/sygnet';
import { CSidebarNav } from '@coreui/vue-pro/src/components/sidebar';
import { CNavTitle } from '@coreui/vue-pro/src/components/nav';
import { CNavItem } from '@/components/CNavItem';

export default {
  name: 'AppSidebar',
  components: {
    CNavItem,
    CNavTitle,
    CSidebarNav,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const store = useStore();
    return {
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    };
  },
  computed: {
    isAdminUser: function (): boolean {
      // @ts-ignore
      return this.$store.getters.isAdminUser;
    }
  }
};
