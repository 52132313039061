import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/app',
    name: 'home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: '/history/:year',
        name: 'history',
        component: () => import('@/views/Dashboard.vue'),
        props: (route) => ({ year: Number.parseInt(route.params.year as string) })
      },
      {
        path: '/applications',
        name: 'applications',
        props: true,
        component: () => import('@/views/pages/ApplicationList.vue'),
      },
      {
        path: '/application/:sectionId?',
        name: 'application',
        props: true,
        component: () => import('@/views/pages/Application.vue'),
      },
      {
        path: '/manage/:sectionId/:eventYear?',
        name: 'section-manage',
        props: true,
        component: () => import('@/views/pages/Manage/ManageParticipants.vue'),
      }
    ]
  },
  {
    path: '/pages/404',
    name: 'Page404',
    component: () => import('@/views/pages/Page404.vue')
  },
  {
    path: '/pages/500',
    name: 'Page500',
    component: () => import('@/views/pages/Page500.vue')
  },
  {
    path: '/pages/login',
    name: 'login-old',
    redirect: '/',
  },
  {
    path: '/pages/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/ResetPassword.vue')
  },
  {
    path: '/pages/logout',
    name: 'logout',
    component: () => import('@/views/pages/Logout.vue')
  },
  {
    path: '/pages/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue')
  },
  {
    path: '/pages/application',
    name: 'apply-public',
    component: () => import('@/views/pages/ApplicationPublicPage.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
